<template>
    <div class="cabinetLock-list">
        <app-list :opt="opt" @get="onGet" ref="appList"></app-list>
        <el-dialog top="30vh" width="480px" title="解绑设备" :visible.sync="unbindDialogVisible"  :close-on-click-modal="false">
            <div class="unbind-body">
                <div>您正在操作解绑，请输入超级管理员登录密码进行安全校验：</div>
                <el-input placeholder="请输入" v-model.trim="password" type="text" class="no-autofill-pwd"></el-input>
                <div style="color:#aaa">提醒：解绑后，管理员和被授权人均不能使用手机开锁，且锁端注册的开锁信息依然存在。</div>
            </div>
            <div class="unbind-btn">
                <el-button @click="unbindDialogVisible = false">取消</el-button>
                <el-button type="primary" @click="unbindDevice()">解绑</el-button>
            </div>
        </el-dialog>
    </div>
</template>
<script>
    let validMAC = (rule, value, callback) => {
        if (value == "" || value == undefined) {
            callback();
        } else {
            //支持输入大小写字母，数字，限制1~32位字符
            let reg = /^[A-z0-9]{1,32}$/;
            if (!reg.test(value)) {
                callback(new Error("MAC格式不对"));
            } else {
                callback();
            }
        }
    };
    export default {
        props: {
            lockType: {
                type: Number,
                default: '',
            }
        },
        data() {
            let _this = this;
            return {
                unbindDialogVisible: false,
                password: '',
                rowData: [],
                opt: {
                    title: "",
                    search: [{
                            key: "lockType",
                            label: "柜锁型号",
                            type: "select",
                            opt: {
                                list: []
                            }
                        }, {
                            key: "lockName",
                            label: "柜锁名称",
                        },
                        {
                            key: "lockCode",
                            label: "柜锁编号",
                        },
                        {
                            key: "phone",
                            label: "管理员手机号码",
                        },
                        {
                            key: "mac",
                            label: "MAC",
                            maxlength: 32,
                            rules: [{
                                validator: validMAC,
                                trigger: ['blur']
                            }]
                        },
                        {
                            key: "bindState",
                            label: "绑锁状态",
                            type: "select-no-filterable",
                            opt: {
                                list: [{
                                        label: '全部',
                                        value: '',
                                    },
                                    {
                                        label: '已解绑',
                                        value: '0',
                                    },
                                    {
                                        label: '已绑定',
                                        value: '1',
                                    },
                                ]
                            }
                        },
                    ],
                    columns: [{
                            label: "柜锁名称",
                            key: "lockName"
                        },
                        {
                            label: "柜锁编号",
                            key: "lockCode"
                        },
                        {
                            label: "柜锁型号",
                            key: "lockTypeName"
                        },
                        {
                            label: "MAC",
                            key: "mac"
                        },
                        {
                            label: "管理员（用户）",
                            key: "realName"
                        },
                        {
                            label: "管理员手机号码",
                            key: "phone"
                        },
                        {
                            label: "最近一次开锁时间",
                            key: "recentUnLockTime"
                        },
                        {
                            label: "锁电量",
                            key: "batteryPowerStr"
                        },
                        {
                            label: "绑锁时间",
                            key: "createTime"
                        },
                        {
                            label: "柜锁绑定状态",
                            key: "bindStateStr",
                        },
                        {
                            label: "操作",
                            key: "action",
                            type: "action-but",
                            opt: {
                                list: [{
                                    label: "详情",
                                    on(row) {
                                        _this.cache.set("lockBindTime", row.createTime);
                                        _this.$router.push("/main/lock/cabinetLog/" + row.mac + '/' + row.lockTypeName + '/' + row
                                            .lockId + '/' + 2);
                                    }
                                }]
                            }
                        }
                    ],
                    buttons: [{
                        type: 2,
                        name: '解绑',
                        icon: 'question',
                        on(data) {
                            if (data.ids.length > 1) {
                                return _this.$message({
                                    showClose: true,
                                    message: "一次最多只能解绑一个设备",
                                    type: "warning"
                                });
                            }
                            if (data.list[0].lockTypeName == "WF-G1K") {
                                return _this.$message({
                                    showClose: true,
                                    message: "不支持解绑该型号的锁",
                                    type: "warning"
                                });
                            }
                            if (data.list[0].bindState == 0) {
                                return _this.$message({
                                    showClose: true,
                                    message: "该设备已解绑",
                                    type: "warning"
                                });
                            }
                            _this.openUnbindDevice(data)
                        }
                    }]
                }
            };
        },
        created() {
            console.log("cabinetLock-list created!!");
            this.$nextTick(() => {
                this.$refs.appList.onRefresh();
            })
            this.getBrandList();
        },
        activated() {},
        deactivated() {
            this.unbindDialogVisible = false
        },
        methods: {
            onGet(opt) {
                let dto = {
                    pageNum: Number(opt.skip),
                    pageSize: Number(opt.limit),
                    lockType: opt.searchForm.lockType ?  Number(opt.searchForm.lockType) : '',
                    lockName: opt.searchForm.lockName,
                    lockCode: opt.searchForm.lockCode,
                    mac: opt.searchForm.mac,
                    phone: opt.searchForm.phone,
                    bindState: opt.searchForm.bindState
                };
                this.post("/device-service/v1/intelligent-lock/query/lock-list", dto, {
                    isUseResponse: true
                }).then(res => {
                    if (res.data.data.data) {
                        for (let i = 0; i < res.data.data.data.length; i++) {
                            let item = res.data.data.data[i];
                            if (item.batteryState) {
                                item.batteryPowerStr = ["正常", "低电"][item.batteryState - 1] + "（" + (item.batteryPower ?
                                    item.batteryPower +
                                    '%' : "") + "）";
                            } else {
                                item.batteryPowerStr = item.batteryPower ? item.batteryPower + '%' : "";
                            }
                            item.bindStateStr = ['已解绑', '已绑定'][item.bindState];
                            item.phone = item.phone ? item.phone : '--';
                            item.recentUnLockTime = item.recentUnLockTime ? item.recentUnLockTime : '--';
                        }
                    } else {
                        res.data.data.data = []
                    }
                    opt.cb(res.data.data);
                });
            },
            getBrandList() {
                this.post('/device-service/v1/device/query/brand-list?type=2').then(res => {
                    res = JSON.parse(JSON.stringify(res).replace(/model/g, "label"));
                    res = JSON.parse(JSON.stringify(res).replace(/innerType/g, "value"));
                    this.opt.search.forEach(item => {
                        if (item.key == 'lockType') {
                            item.opt.list = res;
                        }
                    })
                })
            },
            openUnbindDevice(data) {
                this.password = "";
                this.unbindDialogVisible = true;
                this.rowData = data
            },
            unbindDevice() {
                if(!this.password) {
                    return this.$message({
                        message: "请先输入密码",
                        type: "warning"
                    });
                }
                let dto = {
                    password: this.AES.encrypt(this.password)
                }
                this.post("user-service/v1/user/check-pwd",dto,{
                    isUseResponse: true
                }).then(res => {
                    if(res.data.data){
                        let dto = {
                            deviceId: this.rowData.list[0].lockId
                        }
                        this.post("homelock-service/v1/home-lock/unbind",dto,{
                            isUseResponse: true
                        }).then(res => {
                            if(res.data.code==0){
                                this.unbindDialogVisible = false;
                                this.$refs.appList.get();
                                return this.$message({
                                    message: "解绑成功",
                                    type: "success"
                                });
                            }
                            // }else{
                            //     return this.$message({
                            //         message: "解绑失败",
                            //         type: "warning"
                            //     });
                            // }
                        })
                    }else{
                        return this.$message({
                            message: "密码错误",
                            type: "warning"
                        });
                    }
                })
            }
        }
    };
</script>
<style lang="scss" scoped>
    .cabinetLock-list {
        height: calc(100% - 126px);
    }
    .unbind-body{
        margin: -15px 10px 20px;
        height: 120px;
        display: flex;
        justify-content: space-around;
        flex-direction: column;
    }
    .unbind-btn{
        display: flex;
        justify-content: end;
        margin-top: 10px;
    }
    .no-autofill-pwd {
        /deep/ .el-input__inner {
            -webkit-text-security: disc !important;
        }
    }
</style>