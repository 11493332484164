<template>
    <div class="doorLock-list">
        <app-list :opt="opt" @get="onGet" ref="appList"></app-list>
    </div>
</template>
<script>
    let validRoomNo = (rule, value, callback) => {
        if (value == "" || value == undefined) {
            callback();
        } else {
            //仅支持输入数字、汉字，大小写字母，限制字符长度1~24个字符
            let reg = /^[a-zA-Z0-9\u4e00-\u9fa5]{1,24}$/;
            if (!reg.test(value)) {
                callback(new Error("房号格式不对"));
            } else {
                callback();
            }
        }
    };
    let validMAC = (rule, value, callback) => {
        if (value == "" || value == undefined) {
            callback();
        } else {
            //支持输入大小写字母，数字，限制1~32位字符
            let reg = /^[A-z0-9]{1,32}$/;
            if (!reg.test(value)) {
                callback(new Error("MAC格式不对"));
            } else {
                callback();
            }
        }
    };
    export default {
        data() {
            let _this = this;
            return {
                opt: {
                    title: "",
                    getApartmentListflag: true,
                    search: [
                        {
                            key: "type",
                            label: "门锁型号",
                            type: "select",
                            opt: {
                                list: []
                            }
                        },
                        {
                            key: "apartmentId",
                            label: "房源",
                            type: "select",
                            opt: {
                                list: [],
                                change(data, cb) {
                                    _this.opt.search.forEach(item => {
                                        if (item.key == 'buildingNo') {
                                            cb({
                                                value: "",
                                                key: item.key,
                                            })
                                            item.opt.list = [];
                                        }
                                    })
                                    _this.getBuildList(data);
                                }
                            }
                        },
                        {
                            key: "buildingNo",
                            label: "楼栋",
                            type: "select",
                            opt: {
                                list: [],
                                parent: "apartmentId",
                            }
                        },
                        {
                            key: "roomId",
                            label: "房号",
                            maxlength: 24,
                            rules: [{
                                validator: validRoomNo,
                                trigger: ['blur']
                            }]
                        },
                        // {
                        //   key: "deviceId",
                        //   label: "设备编号"
                        // },
                        {
                            key: "mac",
                            label: "MAC",
                            maxlength: 32,
                            rules: [{
                                validator: validMAC,
                                trigger: ['blur']
                            }]
                        },
                        {
                            key: "state",
                            label: "电量状态",
                            type: "select-no-filterable",
                            opt: {
                                list: [{
                                        label: '全部',
                                        value: '',
                                    },
                                    {
                                        label: '正常',
                                        value: '1',
                                    },
                                    {
                                        label: '低电',
                                        value: '2',
                                    },
                                ]
                            }
                        },
                        {
                            key: "bindRoomState",
                            label: "绑锁状态",
                            type: "select-no-filterable",
                            opt: {
                                list: [{
                                        label: '全部',
                                        value: '',
                                    },
                                    {
                                        label: '已解绑',
                                        value: '0',
                                    },
                                    {
                                        label: '已绑定',
                                        value: '1',
                                    },
                                ]
                            }
                        },
                    ],
                    columns: [
                        // {
                        //   label: "门锁编号",
                        //   key: "lockId",
                        //   on: row => {
                        //     _this.cache.set("lockInfo", row);
                        //     _this.$router.push("/main/lock/log/" + row.mac);
                        //   }
                        // },
                        {
                            label: "房号",
                            key: "roomNo",
                            width: 150,
                            on: row => {
                                _this.cache.set("lockInfo", row);
                                _this.cache.set("lockBindTime", row.createdDate);
                                console.log(row.type)
                                if (row.type == 12 || row.type == 13 || row.type == 14 || row.type == 15 || row
                                    .type == 25 || row.type == 1011) {
                                    _this.$router.push("/main/lock/logs89/" + row.mac + '/' + row.lockId +'/' + row.type);
                                } else {
                                    _this.$router.push("/main/lock/log/" + row.mac + '/' + row.lockId +'/' + row.type);
                                }

                            }
                        },
                        {
                            label: "MAC",
                            key: "mac"
                        },
                        {
                            label: "门锁类型",
                            key: "typeName"
                        },
                        {
                            label: "房源",
                            key: "apartmentName"
                        },
                        {
                            label: "楼栋",
                            key: "buildings",
                            width: 150
                        },
                        {
                            label: "最近一次开锁时间",
                            key: "lockLastOpenTime"
                        },
                        // { label: "电量", key: "batteryPowerStr" },
                        {
                            label: "门锁电量",
                            key: "batteryPowerStr"
                        },
                        // {
                        //   label: "电量状态",
                        //   key: "state"
                        // },
                        {
                            label: "绑锁时间",
                            key: "createdDate"
                        },
                        {
                            label: "门锁与房间绑定状态",
                            key: "bindRoomStateStr",
                        }
                    ],
                    exportButtons: [{
                        type: "Export",
                        url: this.EXport + "/device-service/lockmanagment/export",
                        listName: "绑定记录"
                    }],
                }
            };
        },
        created() {
            console.log("doorLock-list created!!");
            let lockTypeVal = this.cache.get('lockTypeVal');
            if (!lockTypeVal || lockTypeVal == 1) {
                this.$nextTick(() => {
                    this.$refs.appList.onRefresh();
                })
            }
            this.getApartmentList();
            this.getBrandList();
        },
        activated() {
            this.getApartmentList();
            this.getBrandList();
            let roleType = this.cache.getLS("userInfo")["roleType"];
            // 非超级管理员 不显示导出
            if(roleType == 1) {
                this.opt.exportButtons = [{
                    type: "Export",
                    url: this.EXport + "/device-service/lockmanagment/export",
                    listName: "绑定记录"
                }]
            }else {
                this.opt.exportButtons.forEach((item,index) => {
                    if(item.type == "Export") {
                        this.opt.exportButtons.splice(index,1)
                    }
                })
            }
        },

        methods: {
            onGet(opt) {
                let dto = {
                    pageNum: opt.skip,
                    pageSize: opt.limit,
                    ...opt.searchForm
                };
                this.post("device-service/lockmanagment/getLockDeviceList", dto, {
                    isUseResponse: true
                }).then(res => {
                    if (res.data.data) {
                        for (let i = 0; i < res.data.data.length; i++) {
                            let item = res.data.data[i];
                            // item.state = ["正常", "低电"][item.state - 1];
                            // "（"+(item.batteryPower?item.batteryPower+'%':"")+"）"
                            if (item.state) {
                                item.batteryPowerStr = ["正常", "低电"][item.state - 1] + "（" + (item.batteryPower ?
                                    item.batteryPower +
                                    '%' : "") + "）";
                            } else {
                                item.batteryPowerStr = item.batteryPower ? item.batteryPower + '%' : "";
                            }
                            if(item.type == '1004'){
                                item.typeName = 'WF-C1Z'
                            }
                            if(item.type == '1008'){
                                item.typeName = 'WF-X11MG'
                            }
                            if(item.type == '1009'){
                                item.typeName = 'WF-S64G'
                            }
                            if(item.type == '1012'){
                                item.typeName = '密码锁（WF-S75）'
                            }
                            if(item.type == '2003'){
                                item.typeName = 'WF-S53'
                            }
                            if(item.type == '1017'){
                                item.typeName = 'WF-S54'
                            }
                            if(item.type == '2002'){
                                item.typeName = 'WF-X4M'
                            }
                            if(item.type == '1013'){
                                item.typeName = 'WF-W3'
                            }
                            if(item.type == '1014'){
                                item.typeName = 'WF-X4'
                            }
                            if(item.type == '1015'){
                                item.typeName = 'WF-S42'
                            }
                            if(item.type == '1018'){
                                item.typeName = 'WF-S41'
                            }
                            item.bindRoomStateStr = ['已解绑', '已绑定'][item.bindRoomState]
                        }
                    } else {
                        res.data.data = []
                    }

                    opt.cb(res.data);
                });
            },
            //获取房源列表
            getApartmentList() {
                this.opt.search.forEach(item => {
                    if(item.key == 'apartmentId') {
                        this.getApartment().then(res => {
                            item.opt.list = res;
                        })
                    }
                })

            },
            // 获取楼栋
            getBuildList(data) {
                if (!data) {
                    return
                }
                let dto = {
                    apartmentId: data
                }
                this.post('/landlord-service/building/list', dto).then(res => {
                    res = JSON.parse(JSON.stringify(res).replace(/buildName/g, "label"));
                    res = JSON.parse(JSON.stringify(res).replace(/buildCode/g, "value"));
                    this.opt.search.forEach(item => {
                        if (item.key == 'buildingNo') {
                            item.opt.list = res;
                        }
                    })
                })
            },
            getBrandList() {
                this.post('/device-service/v1/device/query/brand-list?type=1').then(res => {
                    res = JSON.parse(JSON.stringify(res).replace(/model/g, "label"));
                    res = JSON.parse(JSON.stringify(res).replace(/innerType/g, "value"));
                    this.opt.search.forEach(item => {
                        if (item.key == 'type') {
                            item.opt.list = res;
                        }
                    })
                })
            },
        }
    };
</script>
<style lang="scss" scoped>
    .doorLock-list {
        height: calc(100% - 126px);
    }
</style>