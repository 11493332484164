<template>
    <div class="personLock-list">
        <app-list :opt="opt" @get="onGet" ref="appList"></app-list>
        <el-dialog top="30vh" width="480px" title="解绑设备" :visible.sync="unbindDialogVisible"  :close-on-click-modal="false">
            <div class="unbind-body">
                <div>您正在操作解绑，请输入超级管理员登录密码进行安全校验：</div>
                <el-input placeholder="请输入" v-model.trim="password" type="text" class="no-autofill-pwd"></el-input>
                <div style="color:#aaa">提醒：解绑后，管理员和被授权人均不能使用手机开锁，且锁端注册的开锁信息依然存在。</div>
            </div>
            <div class="unbind-btn">
                <el-button @click="unbindDialogVisible = false">取消</el-button>
                <el-button type="primary" @click="unbindDevice()">解绑</el-button>
            </div>
        </el-dialog>
    </div>
</template>
<script>
    let validMAC = (rule, value, callback) => {
        if (value == "" || value == undefined) {
            callback();
        } else {
            //支持输入大小写字母，数字，限制1~32位字符
            let reg = /^[A-z0-9]{1,32}$/;
            if (!reg.test(value)) {
                callback(new Error("MAC格式不对"));
            } else {
                callback();
            }
        }
    };
    export default {
        data() {
            let _this = this;
            return {
                unbindDialogVisible: false,
                password: '',
                rowData: [],
                opt: {
                    title: "",
                    search: [{
                            key: "model",
                            label: "门锁型号",
                            type: "select",
                            opt: {
                                list: []
                            }
                        }, 
                        {
                            key: "userName",
                            label: "管理员",
                        },
                        {
                            key: "userPhone",
                            label: "管理员手机号",
                        },
                        {
                            key: "mac",
                            label: "MAC",
                            maxlength: 32,
                            rules: [{
                                validator: validMAC,
                                trigger: ['blur']
                            }]
                        },
                        {
                            key: "state",
                            label: "绑锁状态",
                            type: "select-no-filterable",
                            opt: {
                                list: [{
                                        label: '全部',
                                        value: '',
                                    },
                                    {
                                        label: '已解绑',
                                        value: '1',
                                    },
                                    {
                                        label: '已绑定',
                                        value: '0',
                                    },
                                ]
                            }
                        },
                    ],
                    columns: [{
                            label: "门锁型号",
                            key: "model"
                        },
                        {
                            label: "MAC",
                            key: "mac"
                        },
                        {
                            label: "管理员（用户）",
                            key: "userName"
                        },
                        {
                            label: "管理员手机号码",
                            key: "userPhone"
                        },
                        {
                            label: "最近一次开锁时间",
                            key: "lastActionTime"
                        },
                        {
                            label: "锁电量",
                            key: "batteryPowerStr"
                        },
                        {
                            label: "绑锁时间",
                            key: "createTime"
                        },
                        {
                            label: "绑锁状态",
                            key: "bindStateStr",
                        },
                        {
                            label: "操作",
                            key: "action",
                            type: "action-but",
                            opt: {
                                list: [{
                                    label: "详情",
                                    on(row) {
                                        _this.$router.push("/main/lock/personLog/" + row.deviceId + '/' + row.model + '/' + row.id);
                                    }
                                }]
                            }
                        }
                    ],
                    buttons: [{
                        type: 2,
                        name: '解绑',
                        icon: 'question',
                        on(data) {
                            if (data.ids.length > 1) {
                                return _this.$message({
                                    showClose: true,
                                    message: "一次最多只能解绑一个设备",
                                    type: "warning"
                                });
                            }
                            if (data.list[0].model == "WF-X11MG" || data.list[0].model == "WF-C1Z" || data.list[0].model == "WF-S64G" ||
                             data.list[0].model == "WF-S53" || data.list[0].model == "WF-S54" || data.list[0].model == "WF-X3M" || data.list[0].model == "WF-X4M" || data.list[0].model == "WF-S65") {
                                return _this.$message({
                                    showClose: true,
                                    message: "不支持解绑该型号的锁",
                                    type: "warning"
                                });
                            }
                            if (data.list[0].state == 1) {
                                return _this.$message({
                                    showClose: true,
                                    message: "该设备已解绑",
                                    type: "warning"
                                });
                            }
                            _this.openUnbindDevice(data)
                        }
                    }]
                }
            };
        },
        created() {
            console.log("personLock-list created!!");
            this.$nextTick(() => {
                this.$refs.appList.onRefresh();
            })
            this.getBrandList();
        },
        activated() {},
        deactivated() {
            this.unbindDialogVisible = false
        },
        methods: {
            onGet(opt) {
                let dto = {
                    current: opt.skip,
                    pageSize: opt.limit,
                    category: 'locker',
                    grantType: 1,
                    ...opt.searchForm
                };
                this.post("/homelock-service/user-device/web/api/v1/bind-record", dto, {
                    isUseResponse: true
                }).then(res => {
                    if (res.data.data) {
                        for (let i = 0; i < res.data.data.length; i++) {
                            let item = res.data.data[i];
                            if(item.battery) {
                                if(item.battery < 30) {
                                    item.batteryPowerStr = "低电" + "（" + item.battery + '%' + "）";
                                } else {
                                    item.batteryPowerStr = "正常" + "（" + item.battery + '%' + "）";
                                }
                            }else if(item.battery == 0){
                                item.batteryPowerStr = "低电" + "（" + item.battery + '%' + "）";
                            }
                            item.bindStateStr = ["已绑定","已解绑"][item.state];
                            item.userName = item.userName ? item.userName :'--'
                            item.userPhone = item.userPhone ? item.userPhone : '--';
                            item.lastActionTime = item.lastActionTime ? item.lastActionTime : '--';
                        }
                    } else {
                        res.data.data = []
                    }
                    opt.cb(res.data);
                });
            },
            getBrandList() {
                this.post('/device-service/v1/device/query/brand-list?type=3').then(res => {
                    // res = JSON.parse(JSON.stringify(res).replace(/model/g, "label"));
                    res = JSON.parse(JSON.stringify(res).replace(/model/g, "value"));
                    this.opt.search.forEach(item => {
                        if (item.key == 'model') {
                            item.opt.list = res;
                        }
                    })
                })
            },
            openUnbindDevice(data) {
                this.password = "";
                this.unbindDialogVisible = true;
                this.rowData = data
            },
            unbindDevice() {
                if(!this.password) {
                    return this.$message({
                        message: "请先输入密码",
                        type: "warning"
                    });
                }
                let dto = {
                    password: this.AES.encrypt(this.password)
                }
                this.post("user-service/v1/user/check-pwd",dto,{
                    isUseResponse: true
                }).then(res => {
                    if(res.data.data){
                        let dto1 = {
                            deviceId: this.rowData.list[0].deviceId,
                            userId: this.rowData.list[0].userId,
                        }
                        this.post("homelock-service/api/v1/device/unbundle",dto1,{
                            isUseResponse: true
                        }).then(res => {
                            this.unbindDialogVisible = false;
                            this.$refs.appList.get();
                            return this.$message({
                                message: "解绑成功",
                                type: "success"
                            });
                        })
                    }else{
                        return this.$message({
                            message: "密码错误",
                            type: "warning"
                        });
                    }
                })
            }
        }
    };
</script>
<style lang="scss" scoped>
    .personLock-list {
        height: calc(100% - 126px);
    }
    .unbind-body{
        margin: -15px 10px 20px;
        height: 120px;
        display: flex;
        justify-content: space-around;
        flex-direction: column;
    }
    .unbind-btn{
        display: flex;
        justify-content: end;
        margin-top: 10px;
    }
    .no-autofill-pwd {
        /deep/ .el-input__inner {
            -webkit-text-security: disc !important;
        }
    }
</style>