<template>
  <div class="title">
    <app-header :title="title" :isShowBack="false"></app-header>
    <div style="padding-bottom: 30px;display:inline-block;">
      <span style="padding-right:20px;">锁类型</span>
      <el-select style="width: 300px;display: inline-block;" v-model="lockType" @change="changeLockType(lockType)" :disabled="changeLockTypeFlag">
        <el-option v-for="item in lockTypeList" :key="item.value" :label="item.label" :value="item.value">
        </el-option>
      </el-select>
    </div>
    <doorLockList v-if="lockType == 1" :lockType="lockType"> </doorLockList>
    <cabinetLockList v-if="lockType == 2" :lockType="lockType"> </cabinetLockList>
    <personLockList v-if="lockType == 3" :lockType="lockType"></personLockList>

  </div>
</template>
<script>
  import doorLockList from './doorLockList';
  import cabinetLockList from './cabinetLockList';
  import personLockList from './personLockList'
  export default {
    components: {
      cabinetLockList,
      doorLockList,
      personLockList
    },
    data() {
      return {
        title: "绑定记录",
        lockType: 1, //1-门锁 2-柜锁
        lockTypeList: [{
            label: '平台锁',
            value: 1,
          },
          {
            label: '柜锁',
            value: 2,
          },
          {
            label: '智家锁',
            value: 3,
          }
        ],
        changeLockTypeFlag: false,
      }
    },
    activated() {
      let lockTypeVal = this.cache.get('lockTypeVal');
      if (lockTypeVal) {
        this.lockType = Number(lockTypeVal);
      } else {
        this.lockType = 1;
      }
    },
    created() {
      // 刷新初始化
      console.log('绑锁记录created！！！！！！！！')
      this.lockTypeVal = 1;
      this.cache.set('lockTypeVal', this.lockTypeVal);
      let roleType = this.cache.getLS("userInfo")["roleType"];
      // 非超级管理员 不显示柜锁列表
      if(roleType == 1) {
        this.changeLockTypeFlag = false;
      }else {
        this.changeLockTypeFlag = true;
      }
    },
    mounted() {},
    deactivated() {
      // 离开绑锁记录页面，清除锁类型缓存
      let pathList = this.$route.path.split('/')
      if (pathList[2] != 'lock') {
        this.cache.del('lockTypeVal');
      }
    },
    methods: {
      changeLockType(val) {
        this.lockType = val;
        this.cache.set('lockTypeVal', val);
      }
    },
  }
</script>
<style lang="scss" scoped>

</style>